@import "variables";
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin";

.ag-theme-balham {
  @include ag-theme-balham(
    (
      // Colour of text and icons in primary UI elements like menus
        secondary-foreground-color: $coal-black,
      // Fonts
        font-family: ("Hp Simplified", "Helvetica Neue", sans-serif),
        font-size: 14px,
      // use theme parameters where possible
        balham-active-color: $hp-blue,
      // Background colour for all headers, including the grid header, panels etc
        header-background-color: $light-grey5,
      // grid-size is the main control for affecting how tightly data and UI elements are packed together. All padding and spacing in the grid is defined as a multiple of grid-size, so increasing it will make most components larger by increasing their internal white space while leaving the size of text and icons unchanged.
        grid-size: 5px,
      // Colour of text and icons in the header
        header-foreground-color: ag-derived(secondary-foreground-color),
      // Height of header rows
        header-height: ag-derived(row-height),
    )
  );

  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    text-shadow: $hp-blue;
  }
}
