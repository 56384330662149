@import "./theme/theme";
*{
  font-family: "Hp Simplified", "Helvetica Neue", sans-serif
}

.App{
  background-color: $light-grey4;
}
.launch-page{
  height: 100vh;
  display: flex;
  align-items: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.body{
  background-color: aqua !important;
}
.vl {
  border-left: 1px solid royalblue;
  height: 350px;
}
.rowC{display:flex; flex-direction:row;}
.panelDefault {
  text-align: center;
 }
 .Production {
  font-size: 16px;
  color:royalblue
  }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Grid{
  width: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.headerBar{
  background-color: #0171ad!important;
}
.h{
  font: 400 1.375rem HPSimplified,Arial,sans-serif;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: 400;
    font-stretch: normal;
    font-size: 1.375rem;
    line-height: 1.18;
    font-family: HPSimplified, Arial, sans-serif;
    line-height: 1.18;
}
.p{
  font: 1rem HPSimplifiedLight,Arial,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 1rem;
  line-height: 1.5;
  font-family: HPSimplifiedLight, Arial, sans-serif
}
.Mouse{
  cursor: pointer;
  color: white;
  font-size: 22px;
}
.Button1{
  border-radius: 12 !important;
  padding: "5px 30px" !important;
  font-size: "12px" !important;
  margin-right: 15 !important; 
  margin-top: 15 !important;
}

ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background-color: #eee;
}
.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
 
  color: #bdbdbd;

  margin-bottom: 20px;
}

.dropzoneCancelButton{
  position: absolute;
  right: 35%;
  cursor: pointer;
}
.fW500{
  font-weight: 500;
}
.breadcrumb.wizard {
  padding: 0px;
background: #fff;
list-style: none;
overflow: hidden;
  margin-top: 20px;
font-size: 14px;
}
.breadcrumb.wizard>li+li:before {
padding: 0;
}
.breadcrumb.wizard li {
float: left;
}
.breadcrumb.wizard li.active  {
cursor: pointer;
}
.breadcrumb.wizard li.completed  {
background: brown;                   /* fallback color */
background: $hp-blue;
cursor: pointer;
}
.breadcrumb.wizard li.activeText{
  color: $hp-blue !important;
}
.breadcrumb.wizard li.active :after {
// border-left: 30px solid #ffc107 ;
}
.breadcrumb.wizard li.completed :after {
border-left: 30px solid  $hp-blue;
}

.breadcrumb.wizard li  {
color: white;
text-decoration: none;
padding: 10px 0 10px 45px;
position: relative;
display: block;
float: left;
}
.breadcrumb.wizard li :after {
content: " ";
display: block;
width: 0;
height: 0;
border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
border-bottom: 50px solid transparent;
border-left: 30px solid hsla(0, 0%, 83%, 1);
position: absolute;
top: 50%;
margin-top: -50px;
left: 100%;
z-index: 2;
}
.breadcrumb.wizard li :before {
content: " ";
display: block;
width: 0;
height: 0;
border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
border-bottom: 50px solid transparent;
border-left: 30px solid white;
position: absolute;
top: 50%;
margin-top: -50px;
margin-left: 1px;
left: 100%;
z-index: 1;
}
.breadcrumb.wizard li:first-child a {
padding-left: 15px;
}
.customer-wrap{
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}


.loader {
  position: fixed;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
 
.custom-formControlLabel{
  margin-right: 14px !important;
}

.error{
  margin: 0;
  
  position: absolute;
  top: 50%;
  left: 40%;
  margin-right: -50%;
  
}

.parentComment:hover{
  background-color: #e3e3e3;
}
.parentComment .childComment{
  visibility: hidden;
}

.parentComment:hover .childComment{
  visibility: visible;
}

.commentUserNav{
  padding: 10px 0 0 10px;
}

.commentBodyNav{
  padding: 0 0 0 10px;
}

.comments-scroll{
  min-height:10px;
  max-height:250px;
  overflow: auto;
}