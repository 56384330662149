// Custom theme
// HP Standard

@import "./variables";
@import "./ag-grid";

// Scroll bar
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba($black, 0.2);
  box-shadow: inset 0 0 2px rgba($black, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: $medium-grey;
  outline: 1px solid $light-grey;
}

.mss-content {
  overflow: auto;
  padding-top: 5rem;
  padding-bottom: 0rem;
  height: 100vh;

  .mss-fixed-menu {
    position: fixed;
    left: 0;
  }

  .mss-wrapper {
    margin-left: 380px;
  }
}

a,
.btn-link {
  color: $hp-blue;
  font-weight: 400 !important;
}
.bg-dark {
  background-color: $hp-blue !important;
}
.downloadAttachment{
  color: #fff;
}
.downloadAttachment:hover{
  color: #fff;
}
.navbar {
  padding: 0.8rem 1rem;
  .brand-logo {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url('hp-logo-white.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    vertical-align: bottom;
  }
  .nav-item {
    padding-right: 1em;
    .nav-link {
      display: flex;
      svg {
        font-size: 18px;
        padding-top: 2px;
        margin-right: 6px;
        vertical-align: middle;
      }
      &:hover {
        color: $white;
      }
    }
  }
}

.badge,
.badge-pill {
  font-weight: 100;
  margin-right: 5px;
}


.card-header {
  padding: 0.45rem 1.25rem;
  font-weight: 700;
  svg {
    margin-right: 0.45rem;
    font-size: 1rem;
    vertical-align: text-top;
  }
}

.card-footer {
  svg {
    margin-right: 1rem;
    font-size: 1.75rem;
    color: $hp-blue;
  }
}

.accordion > .card > .card-header {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.btn {
  border-radius: 0;
  min-width: 95px;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  &.btn-primary {
    background-color: $hp-blue;
    border-color: $hp-blue;
  }
  &.dropdown-toggle {
    min-width: inherit;
  }
  & ~ .btn {
    margin-left: 1em;
  }
}

.ag-cell {
  .btn-sm {
    padding: 0 0.25rem;
    font-size: 0.85rem !important;
    line-height: 1.5;
    border-radius: 0.2rem !important;
    min-width: 60px !important;
    letter-spacing: 0 !important;
    text-transform: capitalize !important;
  }
}

// Header
.mss-user {
  line-height: 38px;
  color: $white;
  padding-right: 1.5rem;
  .mss-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 0.5rem;
    line-height: 26px;
    background-color: $white;
    border-radius: 50%;
    text-align: center;
    color: $hp-blue;
    vertical-align: middle;
  }
}

.mss-ellipsis-dropdown {
  .dropdown-toggle::after {
    display: none;
  }
}

.mss-chat-bot {
  position: fixed;
  right: 24px;
  bottom: 40px;
  padding: 0.5rem;
  font-size: 20px;
  min-width: 50px;
}

// Toast
.toast {
  background-color: $dark-grey3;
  color: $white;
  border-radius: 10px;
  padding: .5em 1em 1em;
  .toast-header {
    background-color: transparent;
    color: $white;
    padding: 0;
    font-size: 1.25em;
    button.close {
      color: $white;
      font-size: 42px;
      font-weight: 100;
      line-height: 32px;
    }
  }
  .toast-body {
    padding: 0;
    font-weight: 200;
  }
}

// Footer
.footer {
  background-color: $dark-grey2;
  padding: 0.25rem 1rem;
  color: $white;
  font-size: 14px;
  font-weight: 100;
  // margin-top: 2rem;
  position: fixed;
  left: 0;
  bottom: 0;
  .list-inline {
    .list-inline-item {
      .btn-link {
        color: $white;
        padding: 0;
        text-transform: capitalize;
        letter-spacing: 0;
        font-weight: 100 !important;
      }
    }
  }
}

@media (min-width: 280px) and (max-width: 767px) {
  .mss-fixed-menu {
    position: inherit !important;
    left: inherit !important;
  }

  .mss-wrapper {
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .mss-wrapper {
    margin-left: 220px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mss-wrapper {
    margin-left: 290px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .mss-wrapper {
    margin-left: 340px !important;
  }
}
