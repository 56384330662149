$hp-blue: #0171ad;
$hp-blue2: #0096d6;

$white: #ffffff;
$black: #000000;

// Greyscale
$light-grey: #f2f2f2;
$light-grey2: #eeeeee;
$light-grey3: #e8e8e8;
$light-grey4: #e8eaec;
$light-grey5: #efefef;

$medium-grey: #b9b8bb;
$medium-grey2: #c8c8c8;

$dark-grey: #767676;
$dark-grey2: #757678;
$dark-grey3: #404040;
$coal-black: #282828;

$yellow: #ffb000;
$red: #c80e04;
$hp-green: #008c2c;
